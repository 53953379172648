import { httpMethodType, IBaseQuery } from "@/api/interfaces/IApi";
import { useStore } from "vuex";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { EditData } from "@/components/ui/Modal/Dialog/instances/CategoryModal.vue";

interface editPageData {
  id?: number;
  title?: string;
  description?: string;
  descriptionSEO?: string;
  path?: string;
  active?: boolean;
  image?: string;
}

export function usePages(viewConfiguration: IBaseQuery) {
  const store = useStore();

  async function requestPagesHandler(): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const query = Object.assign(viewConfiguration, { all: true });
    const { success, message } = await store.dispatch("fetchPagesList", {
      query
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  function changeViewConfigurationHandler({
    page,
    perPage
  }: ITableViewConfiguration): void {
    if (
      page === viewConfiguration.page &&
      perPage !== viewConfiguration.perPage
    ) {
      viewConfiguration.page = 1;
    } else {
      viewConfiguration.page = page;
    }

    viewConfiguration.perPage = perPage;
  }

  async function addEditPage(
    data: editPageData,
    method: httpMethodType,
    id = undefined
  ): Promise<boolean> {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("addEditPage", {
      data,
      method,
      id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function removePage(id: number): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("removePage", { id });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function fetchFaqHandler(selectedTab: { id: number | null }): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const query = Object.assign(viewConfiguration, { all: true });
    const { success, message } = await store.dispatch("fetchPageFaq", {
      query,
      id: selectedTab?.id,
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }


  async function addEditPageFaq(
    data: editPageData,
    method: httpMethodType,
    id = undefined
  ): Promise<boolean> {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("addEditPageFaq", {
      data,
      method,
      id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  return {
    requestPagesHandler,
    addEditPage,
    removePage,
    changeViewConfigurationHandler,
    fetchFaqHandler,
    addEditPageFaq
  };
}
