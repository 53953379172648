import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbarTable = _resolveComponent("AppToolbarTable")!

  return (_openBlock(), _createBlock(_component_AppToolbarTable, {
    "pagination-capture-key": "Questions",
    "table-title": _ctx.tableTitle,
    "table-head-tabs": _ctx.categoriesData,
    "table-head-table": _ctx.tableHead,
    "table-body": _ctx.rows,
    "total-rows": _ctx.totalRowsCount,
    "current-page": _ctx.viewConfiguration.page,
    "per-page": _ctx.viewConfiguration.perPage,
    onRemoveItem: _ctx.deletingInfo,
    onEditItem: _ctx.editingInfo,
    onOpenForm: _ctx.openForm,
    onChangeViewConfiguration: _ctx.changeViewConfiguration,
    onSort: _ctx.changeSorting
  }, null, 8, ["table-title", "table-head-tabs", "table-head-table", "table-body", "total-rows", "current-page", "per-page", "onRemoveItem", "onEditItem", "onOpenForm", "onChangeViewConfiguration", "onSort"]))
}