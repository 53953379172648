import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex justify-content-end page-heading-toolbar" }
const _hoisted_3 = { class: "page-heading-toolbar--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_EsimPlansFaqTable = _resolveComponent("EsimPlansFaqTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!
  const _component_FaqModal = _resolveComponent("FaqModal")!
  const _component_DeleteBrandDialog = _resolveComponent("DeleteBrandDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageMainHeading, { title: "Questions" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppButton, {
                type: "secondary",
                size: "lg",
                onClick: _ctx.editingFaq
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BtnContent, {
                    icon: "plus",
                    label: "Add new"
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": !!_ctx.faqList?.length,
      "empty-button-text": "Add question",
      "empty-key": "category",
      "empty-icon": "category",
      onEmptyButtonClick: _ctx.editingFaq
    }, {
      default: _withCtx(() => [
        _createVNode(_component_EsimPlansFaqTable, {
          "faq-list": _ctx.faqList,
          "total-rows-count": _ctx.totalAccountsCount,
          "initial-view-configuration": _ctx.viewConfiguration,
          sort: _ctx.sortConfig,
          onChangeViewConfiguration: _ctx.changeViewConfigurationHandler,
          onRemovingFaq: _ctx.removingFaq,
          onEditingFaq: _ctx.editingFaq
        }, null, 8, ["faq-list", "total-rows-count", "initial-view-configuration", "sort", "onChangeViewConfiguration", "onRemovingFaq", "onEditingFaq"])
      ]),
      _: 1
    }, 8, ["show-table", "onEmptyButtonClick"]),
    _createVNode(_component_FaqModal, {
      title: _ctx.editData?.id ? 'Edit Question' : 'Add Question',
      opened: _ctx.createFaqOpened,
      data: _ctx.editData?.id ? _ctx.editData : null,
      loading: _ctx.isLoading,
      onSubmit: _ctx.actionWithFaq,
      onHide: _ctx.cleanData,
      onCancel: _ctx.cleanData
    }, null, 8, ["title", "opened", "data", "loading", "onSubmit", "onHide", "onCancel"]),
    _createVNode(_component_DeleteBrandDialog, {
      title: _ctx.editData?.title,
      opened: _ctx.deleteFaqOpened,
      loading: _ctx.isLoading,
      "edit-data": _ctx.editData,
      "is-show": false,
      onHide: _ctx.cleanData,
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _ctx.cleanData
    }, null, 8, ["title", "opened", "loading", "edit-data", "onHide", "onConfirm", "onCancel"])
  ], 64))
}