
import { useStore } from "vuex";
import { defineComponent, computed, watch, reactive } from "@vue/runtime-core";
import {
  IUseBaseAppLayout,
  useBaseAppLayout
} from "@/hooks/layout/useBaseAppLayout";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import EsimPlansFaqTable from "@/components/Table/ts/instances/esim-plans-faq/EsimPlansFaqTable.vue";

import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import DeleteBrandDialog from "@/components/ui/Modal/Dialog/instances/DeleteBrandDialog.vue";

import FaqModal from "@/components/ui/Modal/Dialog/instances/EsimPlansFaqModal.vue";
import { IEsimPlansFaq } from "@/api/interfaces/esim-plans-faq/IEsimPlansFaq";
import { useUserTable } from "@/hooks/tables/modules/usersTable/usersTable";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { useEsimPlansFaq } from "@/hooks/esim-plans-faq/useEsimPlansFaq";
import { sortOrders } from "@/enums/main/sortOrders";

export default defineComponent({
  name: "EsimsPlansFaq",
  components: {
    DeleteBrandDialog,
    FaqModal,
    BtnContent,
    AppButton,
    PageMainHeading,
    EsimPlansFaqTable,
    AppTableController
  },

  setup() {
    const store = useStore();

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10
    });
    const options = {
      viewConfiguration,
      selectedTab: {
        id: null
      }
    };

    const {
      requestFaqHandler,
      changeViewConfigurationHandler,
      editQuestion,
      addQuestion,
      removeQuestion
    } = useEsimPlansFaq(options);

    // Page layout refs
    const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
      initialSearchQuery: "",
      onFiltersChange: requestFaqHandler,
      onChangeSearchQuery: () => {
        changeViewConfigurationHandler({
          page: 1,
          perPage: viewConfiguration.perPage
        });
      }
    });

    watch(viewConfiguration, () => requestFaqHandler());

    requestFaqHandler();

    return {
      ...layoutRefs,
      ...useUserTable(),
      viewConfiguration,
      faqList: computed(() => store.getters.esimPlansFaqList),
      totalAccountsCount: computed(
        () => store.getters.totalEsimPlansFaqQuestions
      ),
      requestFaqHandler,
      changeViewConfigurationHandler,
      editQuestion,
      addQuestion,
      removeQuestion
    };
  },

  data() {
    return {
      editText: "",
      editId: 0,
      isLoading: false,
      createFaqOpened: false,
      sortConfig: {
        byName: { order: sortOrders.asc, keyName: "title" }
      },
      deleteFaqOpened: false,
      editData: {} as IEsimPlansFaq | undefined
    };
  },

  computed: {
    emptyText(): string {
      this.requestFaqHandler?.();

      return `No questions found!`;
    }
  },

  methods: {
    async removingFaq(fId: number): Promise<void> {
      this.editId = fId;
      this.editData = this.faqList.find((faqItem: any) => faqItem.id == fId);
      this.deleteFaqOpened = true;
    },

    editingFaq(id: number) {
      typeof id === "number";
      this.editData = this.faqList.find((faqItem: any) => faqItem.id == id);
      this.createFaqOpened = true;
    },

    cleanData(): void {
      this.editData = {} as IEsimPlansFaq | undefined;
      this.deleteFaqOpened = false;
      this.createFaqOpened = false;
    },

    async actionWithFaq(dataFaq: any) {
      this.isLoading = true;
      let success = false;
      if (dataFaq.id) {
        success = await this.editQuestion(dataFaq);
      } else {
        success = await this.addQuestion(dataFaq);
      }
      if (success) {
        this.createFaqOpened = false;
        this.cleanData();
        this.requestFaqHandler?.();
      }
      this.isLoading = false;
    },

    async onDeleteConfirm(): Promise<void> {
      this.isLoading = true;
      const success = await this.removeQuestion(this.editData?.id || -1);
      if (success) {
        this.deleteFaqOpened = false;
        this.cleanData();
        this.requestFaqHandler?.();
      }
      this.isLoading = false;
    }
  }
});
