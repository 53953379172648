
  import TabsDialog from "@/components/ui/Modal/Dialog/TabsDialog.vue";
  import AppInput from "@/components/Form/AppInput.vue";
  import AppButton from "@/components/ui/Buttons/AppButton.vue";
  import AppCheckbox from "@/components/Form/AppCheckbox.vue";
  import { defineComponent, PropType } from "@vue/runtime-core";
  
  interface AddData {
    title: string;
    description: string;
    active: boolean;
  }
  
  interface EditData {
    id: number;
    title: string;
    description: string;
    active: boolean;
  }
  
  export default defineComponent({
    name: "EsimPlansFaqModal",
    components: {
      AppButton,
      AppInput,
      TabsDialog,
      AppCheckbox
    },
    props: {
      data: {
        type: Object as PropType<EditData>,
        required: false
      },
      opened: { type: Boolean, default: false },
      title: { type: String, default: "" },
      buttonText: { type: String, required: false },
      canCancel: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
    },
    emits: {
      submit: null,
      hide: null,
    },
  
    inheritAttrs: false,
    data() {
      return {
        editData: {} as EditData,
        valid: false as boolean,
        selectedTab: 0
      };
    },
    computed: {
      tabs() {
        const details = {
          title: "Details",
          id: 0
        };
  
        return [
          details
        ];
      },
      editModalTitle() {
        return this.title;
      }
    },
    watch: {
      opened() {
        this.selectedTab = 0;
      },
      data(data: EditData): void {
        if (data) {
          this.editData = { ...data };
        } else {
          this.editData = {} as EditData;
        }
      },
      editData: {
        handler(data: AddData) {
          if (
            data?.title?.length > 4
          ) {
            this.valid = true;
          }
        },
        deep: true
      }
    },
    methods: {
      submit() {
        this.$emit("submit", this.editData);
      },
      onChangeErrorState(error: boolean): void {
        this.valid = !error;
      },
      hide() {
        this.editData = {} as EditData;
        this.$emit("hide");
      },
      action() {
        if (this.valid) {
          this.$emit(
            "submit",
            {
              ...this.editData,
              active: !!this.editData?.active
            },
            this.editData.id
          );
          this.editData = {} as EditData;
        }
      }
    }
  });
  