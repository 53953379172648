import { httpMethodType, IBaseQuery } from "@/api/interfaces/IApi";
import { useStore } from "vuex";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import { IQuestions } from "@/api/interfaces/questions/IQuestions";
import { EditData } from "@/components/ui/Modal/Dialog/instances/CategoryModal.vue";

export type useBaseAppLayoutOptions = {
  viewConfiguration: IBaseQuery;
  selectedTab: { id: number | null };
};

export interface categoriesData {
  id: number;
  selected: boolean;
  name: string;
}

interface editDeviceData {
  title: string;
  active: boolean;
  brand?: string;
  icon?: string;
  id?: number;
}

export function useFaq(options: useBaseAppLayoutOptions) {
  const { viewConfiguration, selectedTab } = options;
  const store = useStore();

  function categoriesToSelect(categories: ICategories[]): categoriesData[] {
    return categories.map(el => ({
      id: el.id,
      name: el.title,
      selected: false
    }));
  }
  async function requestQuestionHandler(): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("fetchQuestionList", {
      query: viewConfiguration,
      id: selectedTab.id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
  }
  async function requestCategoriesHandler(): Promise<void> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("fetchCategoriesList", {
      query: viewConfiguration
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  function changeViewConfigurationHandler({
    page,
    perPage
  }: ITableViewConfiguration): void {
    if (
      page === viewConfiguration.page &&
      perPage !== viewConfiguration.perPage
    ) {
      viewConfiguration.page = 1;
    } else {
      viewConfiguration.page = page;
    }

    viewConfiguration.perPage = perPage;
  }
  async function removeQuestion(id: number): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("removeQuestion", { id });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function editQuestion(reqData: IQuestions): Promise<boolean> {
    const { id, ...data } = reqData;
    const { success, message } = await store.dispatch("editQuestion", {
      data,
      id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function addQuestion(data: IQuestions): Promise<boolean> {
    const { success, message } = await store.dispatch("addQuestion", {
      data: {
        categoryId: data.category,
        ...data
      }
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function addEditCategory(
    dataCategory: EditData,
    method: httpMethodType
  ): Promise<boolean> {
    await store.dispatch("setAppLoading", true);
    const { active, title, id } = dataCategory;
    const { success, message } = await store.dispatch("addEditCategory", {
      data: { active, title },
      method,
      file: dataCategory.file,
      id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function removeCategory(id: number): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("removeCategory", { id });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }
  return {
    editQuestion,
    addQuestion,
    removeQuestion,
    categoriesToSelect,
    changeViewConfigurationHandler,
    requestQuestionHandler,
    requestCategoriesHandler,
    addEditCategory,
    removeCategory
  };
}
