import { httpMethodType, IBaseQuery } from "@/api/interfaces/IApi";
import { useStore } from "vuex";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { ICreateLocalizationDto } from "@/api/services/localizations/localizationsTypes";
import { isImage } from "../locations/new/modules/location.update";
import { computed, reactive, ref, watch } from "vue";
import { api } from "@/api/Api";
import { ILocalizations } from "@/api/interfaces/localizations/ILocalizations";
import {
  IUseBaseAppLayout,
  useBaseAppLayout
} from "../layout/useBaseAppLayout";

interface editLocalizationData {
  id?: number;
  short_name?: string;
  full_name?: string;
  icon?: string;
  active?: boolean;
}

export default function useLocalizations() {
  const store = useStore();
  const viewConfiguration: ITableViewConfiguration = reactive({
    page: 1,
    perPage: 10
  });

  const initialLocalization: editLocalizationData = {
    id: -1,
    short_name: "",
    full_name: "",
    icon: "",
    active: false
  };

  const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
    initialSearchQuery: "",
    onFiltersChange: requestLocalizationsHandler,
    onChangeSearchQuery: () => {
      changeViewConfigurationHandler({
        page: 1,
        perPage: viewConfiguration.perPage
      });
    }
  });

  watch(viewConfiguration, () => requestLocalizationsHandler());

  requestLocalizationsHandler();
  const localizations = computed(() => store.getters.localizations);
  const totalLocalizationsCount = computed(
    () => store.getters.totalLocalizations
  );

  const modalActionLoading = ref(false);
  const updateModalOpened = ref(false);
  const deletePageOpened = ref(false);
  const editId = ref(0);
  const editData = ref({} as ILocalizations | undefined);

  async function requestLocalizationsHandler(): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const query = Object.assign(viewConfiguration, { all: true });
    const { success, message } = await store.dispatch("fetchLocalizations", {
      query
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  function changeViewConfigurationHandler({
    page,
    perPage
  }: ITableViewConfiguration): void {
    if (
      page === viewConfiguration.page &&
      perPage !== viewConfiguration.perPage
    ) {
      viewConfiguration.page = 1;
    } else {
      viewConfiguration.page = page;
    }

    viewConfiguration.perPage = perPage;
  }

  async function saveIcon(
    action: "create" | "edit",
    data: {
      short_name: string;
      active: boolean;
      icon?: string;
      full_name: string;
    },
    updatedLocation: editLocalizationData,
    id: number | null
  ) {
    if (action === "create") return;

    const { short_name, active, icon, full_name } = data;

    const payload: ICreateLocalizationDto = {
      short_name,
      active,
      images: {},
      full_name
    };

    isImage(icon) && (payload.images.icon = icon as string);

    const res = id && (await updateLocalization(id, payload));
    await requestLocalizationsHandler();
    return res;
  }

  async function updateLocalization(
    id: number,
    payload: ICreateLocalizationDto
  ) {
    modalActionLoading.value = true;

    const { success, message, data } = await api.editLocalization(payload, id);
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }
    modalActionLoading.value = false;
    return data;
  }

  async function saveUpdates(
    action: "create" | "edit",
    data: {
      short_name: string;
      active: boolean;
      icon?: string;
      full_name: string;
    },
    id?: number | null
  ) {
    const { short_name, active, icon, full_name } = data;

    const payload: ICreateLocalizationDto = {
      short_name,
      active,
      images: {},
      full_name
    };

    isImage(icon) && (payload.images.icon = icon as string);
    const res =
      action === "create"
        ? await createLocalization(payload)
        : id && (await updateLocalization(id, payload));

    updateModalOpened.value = false;
    await requestLocalizationsHandler();
    endUpdating();

    return res;
  }

  async function createLocalization(payload: ICreateLocalizationDto) {
    modalActionLoading.value = true;

    const { success, message, data } = await api.createLocalization(payload);
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }
    modalActionLoading.value = false;
    return data;
  }

  async function onDeleteConfirm(): Promise<void> {
    modalActionLoading.value = true;
    if (!editData.value?.id) return;
    const { success, message } = await api.removeLocalization(editData.value.id);
    if (success) {
      deletePageOpened.value = false;
      endUpdating();
      requestLocalizationsHandler?.();
    }
    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }
    modalActionLoading.value = false;
  }

  async function removeLocalization(cId: number): Promise<void> {
    editId.value = cId;
    editData.value = localizations.value.find(
      (l: ILocalizations) => l.id == cId
    );
    deletePageOpened.value = true;
  }

  function endUpdating(): void {
    updateModalOpened.value = false;
    deletePageOpened.value = false;
    editData.value = { ...initialLocalization } as ILocalizations | undefined;
  }

  function editLocalization(data: ILocalizations) {
    editId.value = data?.id;
    updateModalOpened.value = true;

    editData.value = data;
  }

  function setModalLoading(loading: boolean) {
    modalActionLoading.value = loading;
  }

  return {
    updateModalOpened,
    deletePageOpened,
    editId,
    editData,
    localizations,
    totalLocalizationsCount,
    modalActionLoading,
    ...layoutRefs,
    setModalLoading,
    requestLocalizationsHandler,
    changeViewConfigurationHandler,
    saveIcon,
    saveUpdates,
    onDeleteConfirm,
    editLocalization,
    endUpdating,
    removeLocalization
  };
}
