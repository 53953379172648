import { httpMethodType, IBaseQuery } from "@/api/interfaces/IApi";
import { useStore } from "vuex";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { ICategories } from "@/api/interfaces/categories/ICategories";
import { IQuestions } from "@/api/interfaces/questions/IQuestions";
import { EditData } from "@/components/ui/Modal/Dialog/instances/CategoryModal.vue";

export type useBaseAppLayoutOptions = {
  viewConfiguration: IBaseQuery;
  selectedTab: { id: number | null };
};

interface editFaqData {
  title: string;
  active: boolean;
  description: string;
  id?: number;
}

export function useEsimPlansFaq(options: useBaseAppLayoutOptions) {
  const { viewConfiguration, selectedTab } = options;
  const store = useStore();


  async function requestFaqHandler(): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("fetchEsimPlansFaqList", {
      query: viewConfiguration,
      id: selectedTab.id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
  }

  function changeViewConfigurationHandler({
    page,
    perPage
  }: ITableViewConfiguration): void {
    if (
      page === viewConfiguration.page &&
      perPage !== viewConfiguration.perPage
    ) {
      viewConfiguration.page = 1;
    } else {
      viewConfiguration.page = page;
    }

    viewConfiguration.perPage = perPage;
  }

  async function removeQuestion(id: number): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("removeEsimPlansFaqQuestion", { id });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function editQuestion(reqData: IQuestions): Promise<boolean> {
    const { id, ...data } = reqData;
    const { success, message } = await store.dispatch("editEsimPlansFaqQuestion", {
      data,
      id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function addQuestion(data: IQuestions): Promise<boolean> {
    const { success, message } = await store.dispatch("addEsimPlansFaqQuestion", {
      data: {
        categoryId: data.category,
        ...data
      }
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  return {
    editQuestion,
    addQuestion,
    removeQuestion,
    changeViewConfigurationHandler,
    requestFaqHandler,
  };
}
