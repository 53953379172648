import { ref } from "@vue/runtime-core";
import { Ref } from "vue";
import { useStore } from "vuex";
import useLocalizations from "@/hooks/localizations/useLocalizations";
import { ILocalizations } from "@/api/interfaces/localizations/ILocalizations";
import {
  IFetchFaqLocalizationData,
  IMappedFaqLocalization
} from "@/api/services/faq/faqLocalizationsTypes";
import { useBaseAppLayoutOptions } from "./useFaq";

export default function useAboutLocalizations(
  options: useBaseAppLayoutOptions
) {
  const { selectedTab } = options;
  const store = useStore();
  const localizationList: Ref<IMappedFaqLocalization[]> = ref([]);
  const { localizations } = useLocalizations();

  const fetchFaqLocalization = async (faqRecordId: number) => {
    const { data } = await store.dispatch("fetchFaqLocalizations", {
      faqRecordId,
      type: selectedTab?.id ? "question" : "category"
    });
    return data;
  };

  const mapFaq = (
    faqLocalization: IFetchFaqLocalizationData[],
    id: number
  ): IMappedFaqLocalization[] => {
    return localizations.value.map((localization: ILocalizations) => {
      const matchingLocalization = faqLocalization?.find(
        (localizationItem: any) =>
          localizationItem.localization?.id === localization?.id
      );
      const title = matchingLocalization ? matchingLocalization.title : "";
      const description = matchingLocalization
        ? matchingLocalization.description
        : "";
      return {
        ...localization,
        id: matchingLocalization?.id || null,
        localizationId: localization?.id,
        title,
        description,
        faqRecordId: id || -1
      };
    });
  };

  const startEditing = async (id: number) => {
    const faqLocalization: IFetchFaqLocalizationData[] = await fetchFaqLocalization(
      id
    );

    localizationList.value = mapFaq(faqLocalization, id);
  };

  const saveLocalization = async (
    localization: IMappedFaqLocalization
  ): Promise<boolean> => {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("editFaqLocalization", {
      editData: localization,
      type: selectedTab?.id ? "question" : "category"
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    cleanLocalizationData();
    await store.dispatch("setAppLoading", false);
    return success;
  };

  const cleanLocalizationData = () => {
    localizationList.value = [];
  };

  return {
    localizations,
    localizationList,
    startEditing,
    saveLocalization,
    cleanLocalizationData
  };
}
