
import { sortItem, sortOrders } from "@/enums/main/sortOrders";

import {
  ComputedRef,
  defineComponent,
  PropType,
  computed,
  toRefs,
  watch
} from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { IEsimPlansFaq } from "@/api/interfaces/esim-plans-faq/IEsimPlansFaq";
import { badgeTypes } from "@/components/ui/AppBadge/interfaces";

type tableHeadCells = ITableHeadCell[];

export type faqTableSort = {
  byName: sortItem;
};

export default defineComponent({
  name: "EsimPlansFaqTable",
  components: { AppTable },

  emits: {
    toggleBlock: null,
    delete: null,
    changeViewConfiguration: null,
    removingFaq: null,
    editingFaq: null
  },
  setup(props, { emit }) {
    // base

    const { byName } = toRefs(props.sort);

    const initialSortOptions: faqTableSort = {
      byName: { order: sortOrders.turnOff, keyName: "title" }
    };

    // table model
    const faqList: ComputedRef<IEsimPlansFaq[]> = computed(() => props.faqList);
    const tableRefs = useBaseTable({
      initialViewConfiguration: { ...props.initialViewConfiguration },

      initialSortOptions,
      model: faqList,
      generateRows(faqList: IEsimPlansFaq[]): ITableBodyRow[] {
        return faqList.map(
          (faq: IEsimPlansFaq): ITableBodyRow => {
            const { title, active, id } = faq;
            return {
              cells: [
                {
                  label: title,
                  sort: {
                    keyName: byName?.value?.keyName || "",
                    value: title,
                    order: byName?.value?.order
                  }
                },
                {
                  badge: {
                    type: active
                      ? badgeTypes.lightSecondary
                      : badgeTypes.danger,

                    label: active ? "Active" : "Inactive"
                  }
                },
                {
                  userActions: true
                }
              ],
              id: id
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    watch(
      props.initialViewConfiguration,
      (updatedConfiguration: ITableViewConfiguration) => {
        changeViewConfiguration(updatedConfiguration);
      }
    );

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    faqList: {
      type: Array as PropType<IEsimPlansFaq[]>,
      required: true
    },

    sort: {
      type: Object as PropType<faqTableSort>,
      required: true
    },

    initialViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },

  computed: {
    tableHead(): tableHeadCells {
      const { byName } = this.currentSort;
      return [
        {
          sort: {
            keyName: byName?.keyName,
            order: byName?.order
          },
          size: "fluid",
          label: "name",
          id: "faq-name"
        },
        {
          size: "sm",
          label: "Status",
          id: "faq-status"
        },
        {
          size: "xs",
          label: "Action",
          id: "faq-action"
        }
      ];
    }
  },

  methods: {
    DeletingInfo(faqId: any, info: IEsimPlansFaq) {
      this.$emit("removingFaq", faqId, info);
    },

    EditingInfo(faqId: IEsimPlansFaq, info: IEsimPlansFaq) {
      this.$emit("editingFaq", faqId, info);
    }
  }
});
