import { computed, ComputedRef, Ref, ref, watch } from "vue";
import {
  ICreateLocationDto,
  IFetchLocationLocalizationData,
  ILocationFaqList,
  IMappedLocalization,
  IMappedLocationFaqList,
  selectedLocationListType
} from "@/api/services/locations/locationTypes";
import {
  IBaseLocation,
  ISubregion
} from "@/api/services/packages/esim/location-packages/types";

import { ILocationCountry, TLocationKeys } from "@/store/locations";
import { ILocationsCtx } from "../useLocations";
import { switchedLocation } from "@/components/Locations/interfaces";
import { api } from "@/api/Api";
import {
  TPackageLocation,
  TPackageLocationSingle
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import useLocalizations from "@/hooks/localizations/useLocalizations";
import { ILocalizations } from "@/api/interfaces/localizations/ILocalizations";

const listTypes: selectedLocationListType[] = ["country", "subregion"];

export interface ILocationSelected {
  countries: ILocationCountry[];
  subregions: ISubregion[];
}

export type editLocationData = {
  id: number;
  title: string;
  icon: string;
  smallImage: string;
  bigImage: string;
  active: boolean;
  isoCode?: string;
  popular?: boolean;
  titleSEO: string;
  descriptionSEO?: string;
  advantagesHeader?: string;
  advantagesTitle?: string;
  advantagesDescription?: string;
  keywords: string;
  // localizations: localizationsLocation[];
};
export type localizationsLocation = {
  id: number;
  name: string;
  localizationId: number;
};

const initialLocation: editLocationData = {
  title: "",
  icon: "",
  smallImage: "",
  bigImage: "",
  active: false,
  isoCode: "",
  popular: false,
  id: -1,
  titleSEO: "",
  descriptionSEO: "",
  advantagesHeader: "",
  advantagesTitle: "",
  advantagesDescription: "",
  keywords: ""
};

export type selectedlocations = {
  countries: IBaseLocation[];
  subregions: IBaseLocation[];
};

export type locationImageVariants = "icon" | "smallImage" | "bigImage";

const initialSavedLocations: selectedlocations = {
  countries: [],
  subregions: []
};

export const isImage = (image: any): boolean =>
  image && typeof image === "string";

export function updateLocation<T extends TLocationKeys>(ctx: ILocationsCtx<T>) {
  const baseLoading = ref(false);
  const baseLocationsLoading = ref(false);
  const modalActionLoading = ref(false);

  const location = ref({ ...initialLocation });
  const { localizations } = useLocalizations();
  const localizationList: Ref<IMappedLocalization[]> = ref([]);
  const locationFaqList: Ref<IMappedLocationFaqList[]> = ref([]);
  const savedLocation: Ref<IBaseLocation | null> = ref(null);

  const selectedList: Ref<selectedlocations> = ref({
    ...initialSavedLocations
  });

  const localSelectedList: Ref<selectedlocations> = ref({
    ...initialSavedLocations
  });

  const changeList: Ref<switchedLocation[]> = ref([]);
  const updateModalOpened = ref(false);

  const countries = computed(
    () => ctx.store.getters.notExtendedCountries as IBaseLocation[]
  );
  const subregions = computed(
    () => ctx.store.getters.notExtendedSubregions as IBaseLocation[]
  );

  ctx.store.dispatch("fetchNotExtendedLocations").finally(() => {
    baseLocationsLoading.value = false;
  });

  watch(() => changeList.value, changeListChangeHandler, { deep: true });
  watch(() => selectedList.value, selecetdListChangeHandler, { deep: true });

  function selecetdListChangeHandler({
    countries,
    subregions
  }: selectedlocations) {
    localSelectedList.value = {
      countries: [],
      subregions: []
    };
    const newCountry: any = countries;
    const newSubregion: any = subregions;
    if (newCountry.selected?.length) {
      localSelectedList.value.countries = [...newCountry.selected];
    }
    if (newSubregion.selected?.length) {
      localSelectedList.value.subregions = [...newSubregion.selected];
    }
    if (!newSubregion.selected?.length && !newCountry.selected?.length) {
      localSelectedList.value.subregions = [];
      localSelectedList.value.countries = [];
    }
  }

  function changeListChangeHandler(
    locations: switchedLocation[],
    prev?: switchedLocation[]
  ) {
    const removedFromChangeList = prev
      ? prev.filter(({ id }) => !locations.some(l => l.id === id))
      : [];

    locations.forEach(({ id, action, type = "countries" }) => {
      if (action === "enable") {
        const isExist = localSelectedList.value[type].find(l => l.id === id);

        if (!isExist) {
          const list = type === "countries" ? countries : subregions;
          const fullLocation: IBaseLocation | null =
            list.value.find(item => item.id === id) || null;

          if (fullLocation) {
            localSelectedList.value[type].push(fullLocation);
          }
        }
      } else if (action === "disable") {
        localSelectedList.value[type] = localSelectedList.value[type].filter(
          l => l.id !== id
        );
      }
    });

    removedFromChangeList.forEach(({ id, action, type = "countries" }) => {
      const data: any = selectedList.value[type];
      const isEnabledByServer = data.selected?.find((l: any) => l.id === id);

      if (action === "enable") {
        if (!isEnabledByServer) {
          localSelectedList.value[type] = localSelectedList.value[type].filter(
            l => l.id !== id
          );
        }
      } else {
        const isExist = localSelectedList.value[type].find(l => l.id === id);

        if (isEnabledByServer && !isExist) {
          const list = type === "countries" ? countries : subregions;
          const fullLocation: IBaseLocation | null =
            list.value.find(item => item.id === id) || null;

          if (fullLocation) {
            localSelectedList.value[type].push(fullLocation);
          }
        }
      }
    });
  }

  async function updateLocation(id: number, payload: ICreateLocationDto) {
    modalActionLoading.value = true;

    const res = await api.editLocation(
      payload,
      ctx.locationTypeSingle.value,
      id
    );

    modalActionLoading.value = false;
    return res;
  }

  async function createLocation(payload: ICreateLocationDto) {
    modalActionLoading.value = true;

    const res = await api.createLocation(payload, ctx.locationTypeSingle.value);

    modalActionLoading.value = false;
    return res;
  }

  return {
    location,
    localizationList,
    locationFaqList,
    selectedList,
    countries,
    subregions,
    updateModalOpened,
    modalActionLoading,
    localSelectedList,
    changeList,
    modalLoading: computed(
      () => baseLoading.value || baseLocationsLoading.value
    ),

    setModalLoading: (loading: boolean) => (baseLoading.value = loading),

    getSelectedLocations(
      id: number
    ): Promise<{
      success: boolean;
      countries: ILocationCountry[];
      subregions: ISubregion[];
    }> {
      baseLoading.value = true;

      return Promise.all(
        listTypes.map(listType => {
          return ctx.store.dispatch("fetchSelectedLocations", {
            listType,
            locationType: ctx.locationTypeSingle.value,
            locationId: id
          });
        })
      )
        .then(([{ data: countries = [] }, { data: subregions = [] }]) => {
          return { success: true, countries, subregions };
        })
        .catch(() => ({ success: false, countries: [], subregions: [] }))
        .finally(() => (baseLoading.value = false));
    },

    startAddLocation() {
      location.value = { ...initialLocation };
      selectedList.value = { countries: [], subregions: [] };
      updateModalOpened.value = true;
    },
    async fetchLocationLocalization(
      locationId: number,
      locationType: TPackageLocationSingle
    ) {
      const { data } = await ctx.store.dispatch(
        "fetchLocalizationLocalization",
        {
          locationId,
          locationType
        }
      );
      return data;
    },
    async fetchLocationFaqList(
      locationId: number,
      locationType: TPackageLocationSingle
    ) {
      const { data } = await ctx.store.dispatch("fetchLocationFaqList", {
        locationId,
        locationType
      });
      const result = data?.results ?? [];
      locationFaqList.value = result;
      return result;
    },
    mapLocalization(
      locationLocalization: IFetchLocationLocalizationData[],
      id: number
    ): IMappedLocalization[] {
      return localizations.value.map((localization: ILocalizations) => {
        const matchingLocalization = locationLocalization?.find(
          (localizationItem: any) =>
            localizationItem.localization?.id === localization?.id
        );
        const name = matchingLocalization ? matchingLocalization.name : "";
        return {
          ...localization,
          id: matchingLocalization?.id || null,
          localizationId: localization?.id,
          name,
          locationId: id || -1
        };
      });
    },
    mapLocationFaq(
      locationFaqList: ILocationFaqList[],
      id: number
    ): IMappedLocationFaqList[] {
      return locationFaqList.map((locatoinFaq: ILocationFaqList) => {
        return {
          ...locatoinFaq,
          locationId: id || -1
        };
      });
    },
    async startEditing(id: number) {
      const locationLocalization: IFetchLocationLocalizationData[] = await this.fetchLocationLocalization(
        id,
        ctx.locationTypeSingle.value
      );

      const locationFaqListData: ILocationFaqList[] = await this.fetchLocationFaqList(
        id,
        ctx.locationTypeSingle.value
      );

      localizationList.value = this.mapLocalization(locationLocalization, id);
      locationFaqList.value = this.mapLocationFaq(locationFaqListData, id);

      if (ctx.locationTypeSingle.value !== "country") {
        const {
          countries,
          subregions,
          success
        } = await this.getSelectedLocations(id);

        if (success) {
          selectedList.value = {
            countries,
            subregions
          };
        }
      }

      updateModalOpened.value = true;

      const currentLocation = ctx.locationsList.value
        .map(l => l)
        .find(location => location.id === id);

      if (currentLocation) {
        savedLocation.value = currentLocation;
      }

      if (currentLocation) {
        const {
          name,
          active,
          id,
          icon,
          smallImage,
          bigImage,
          popular,
          isoCode,
          keywords,
          titleSEO,
          descriptionSEO,
          advantagesHeader,
          advantagesTitle,
          advantagesDescription
        } = currentLocation;

        location.value = {
          title: name,
          active,
          id,
          icon,
          smallImage: smallImage ?? "",
          bigImage: bigImage ?? "",
          popular,
          isoCode,
          titleSEO,
          descriptionSEO,
          advantagesHeader,
          advantagesTitle,
          advantagesDescription,
          keywords
        };
      }
    },

    createLocation,
    updateLocation,

    async saveUpadtes(
      action: "create" | "edit",
      data: {
        name: string;
        active: boolean;
        isoCode?: string;
        popular?: boolean;
        icon?: string;
        smallImage?: string;
        bigImage?: string;
        titleSEO: string;
        descriptionSEO?: string;
        advantagesHeader?: string;
        advantagesTitle?: string;
        advantagesDescription?: string;
        keywords: string;
      },
      id?: number | null
    ) {
      const {
        name,
        active,
        icon,
        smallImage,
        bigImage,
        isoCode,
        popular,
        titleSEO,
        descriptionSEO,
        advantagesHeader,
        advantagesTitle,
        advantagesDescription,
        keywords
      } = data;

      const payload: ICreateLocationDto = {
        name,
        active,
        images: {},
        titleSEO,
        descriptionSEO,
        advantagesHeader,
        advantagesTitle,
        advantagesDescription,
        keywords
      };

      if (ctx.locationTypeSingle.value !== "country") {
        payload.changedLocations = {
          changeList: changeList.value.map(({ action, id }) => ({
            action,
            id
          })),

          locationChildType: changeList.value[0]?.type || "countries",
          locationParentType: ctx.locationTypeSingle.value
        };
      } else {
        payload.isoCode = isoCode;
        payload.popular = popular;
      }

      isImage(icon) && (payload.images.icon = icon as string);
      isImage(smallImage) && (payload.images.smallImage = smallImage as string);
      isImage(bigImage) && (payload.images.bigImage = bigImage as string);

      const res =
        action === "create"
          ? await createLocation(payload)
          : id && (await updateLocation(id, payload));

      updateModalOpened.value = false;
      await ctx.requestLocations?.();
      changeList.value = [];

      return res;
    },

    async saveIcon(
      action: "create" | "edit",
      data: {
        name: string;
        active: boolean;
        icon?: string;
        smallImage?: string;
        bigImage?: string;
        titleSEO: string;
        descriptionSEO?: string;
        advantagesHeader?: string;
        advantagesTitle?: string;
        advantagesDescription?: string;
        keywords: string;
      },
      updatedLocation: editLocationData,
      id: number | null
    ) {
      if (action === "create") return;

      const {
        name,
        active,
        icon,
        smallImage,
        bigImage,
        titleSEO,
        descriptionSEO,
        advantagesHeader,
        advantagesTitle,
        advantagesDescription,
        keywords
      } = data;

      const payload: ICreateLocationDto = {
        name,
        active,
        images: {},
        titleSEO,
        descriptionSEO,
        advantagesHeader,
        advantagesTitle,
        advantagesDescription,
        keywords
      };

      isImage(icon) && (payload.images.icon = icon as string);
      isImage(smallImage) && (payload.images.smallImage = smallImage as string);
      isImage(bigImage) && (payload.images.bigImage = bigImage as string);

      const res = id && (await updateLocation(id, payload));
      await ctx.requestLocations?.();
      location.value = updatedLocation;
      return res;
    },

    endUpdating() {
      updateModalOpened.value = false;
      location.value = { ...initialLocation };
      selectedList.value = { countries: [], subregions: [] };
      changeList.value = [];
      savedLocation.value = null;
    },

    setLocation(updatedLocation: editLocationData) {
      location.value = updatedLocation;
    },

    toggleSelectedLocation(location: switchedLocation) {
      const { action, id, type = "countries" } = location;
      const inChangesList = changeList.value.find(item => item.id === id);
      const data: any = selectedList.value[type];
      const enabledByServer = data?.selected?.find(
        (item: any) => item.id === id
      );

      if (action === "disable") {
        if (enabledByServer) {
          if (inChangesList) {
            inChangesList.action = "disable";
          } else {
            changeList.value.push(location);
          }
        } else {
          changeList.value = changeList.value.filter(item => item.id !== id);
        }
      } else if (action === "enable") {
        if (enabledByServer) {
          changeList.value = changeList.value.filter(item => item.id !== id);
        } else if (inChangesList) {
          inChangesList.action = "enable";
        } else {
          changeList.value.push(location);
        }
      }
    },

    async deleteLocation(id: number, locationType: TPackageLocation) {
      modalActionLoading.value = true;
      const res = await api.deleteLocation({ id, locationType });
      modalActionLoading.value = false;
      ctx.requestLocations?.();
      return res;
    },

    async saveLocalization(
      data: localizationsLocation[],
      locationType: TPackageLocationSingle
    ) {
      modalActionLoading.value = true;
      updateModalOpened.value = false;
      const { success, message } = await api.saveLocalization(
        data,
        locationType
      );

      if (!success && message) {
        await ctx.store.dispatch("showErrorNotification", message);
      }
      modalActionLoading.value = false;
      localizationList.value = [];
      locationFaqList.value = [];
      ctx.requestLocations?.();
    }
  };
}
